import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Logo } from '../../components/library'


function actions() {
    return (
        <LibraryWrapper>
           <Logo />
        </LibraryWrapper>
    )
}
export default actions
